.landing-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  flex-wrap: nowrap;
}

.background-image {
  position: absolute;
  width: 100vw;
  bottom: 0;
  z-index: -1;
}

.landing-page-left {
  height: 100vh;
  width: 50vw;
  gap: 40px;
}

.landing-page-left-image {
  width: 500px;
}

.landing-page-left-para {
  color: #000;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 500px;
}

.landing-page-right {
  width: 50vw;
  gap: 50px;
}

.landing-page-right-para {
  color: #000;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.landing-page-right-input-image {
  height: 40px;
  padding-left: 5px;
}

.landing-page-header-container {
  color: #000;
  font-family: "Major-Mono-Display";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ladning-page-right-header section {
  display: contents;
  color: #116274;
}

.landing-page-right-input-container {
  width: 400px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid #000;
  flex-wrap: nowrap;
  gap: 5px;
  padding-left: 5px;
}

.landing-page-right-input {
  width: -webkit-fill-available;
  height: 75%;
  border: none;
  margin: 10px;
  color: #877c7c;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.landing-page-right-button {
  width: 300px;
  height: 50px;
  border-radius: 40px;
  background-color: orange;
  color: black;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.landing-page-right-input:focus {
  outline: none;
}

.typing-animation-container {
  padding: 15px;
}

.typing {
  width: 10px;
  height: 10px;
  margin: 0 3px;
  background-color: white;
  border-radius: 50%;
  animation: typingAnimation 0.6s infinite ease-in-out;
}

.dot1 {
  animation-delay: 0s;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.4s;
}

@keyframes typingAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
