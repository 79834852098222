@font-face {
  font-family: "Work-Sans";
  src: url("./assets/fonts/Work-Sans.ttf");
}

@font-face {
  font-family: "Major-Mono-Display";
  src: url("./assets/fonts/MajorMonoDisplay-Regular.ttf");
}

@font-face {
  font-family: "Source-Code-Pro";
  src: url("./assets/fonts/SourceCodePro.ttf");
}

@font-face {
  font-family: "Noto-Sans";
  src: url("./assets/fonts/NotoSans.ttf");
}

a {
  text-decoration: none;
  color: white;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

body {
  margin: 0;
  padding: 0;
  color: black;
  font-family: "Work-Sans";
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.flex-item {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  margin: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
