.new-post-page {
  width: 100vw;
}

.new-post-container {
  width: 700px;
  border-radius: 15px;
  border: 1px solid #000;
  padding: 40px;
  gap: 30px;
}

.new-post-input-container {
  display: flex;
  width: -webkit-fill-available;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.new-post-input-label {
  color: #666;
  font-family: "Noto-Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.new-post-input {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: var(--W-Background, #fff);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.new-post-buttons-container {
  margin-top: 50px;
  gap: 40px;
}

.new-post-image-upload-container {
  cursor: pointer;
  gap: 20px;
  color: var(--Dark, #222);
}

.new-post-image {
  width: 342px;
  height: 198px;
}
