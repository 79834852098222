body {
  overflow: auto;
}

.posts-container {
  gap: 70px;
  flex-wrap: wrap;
  margin-bottom: 100px;
  padding: 50px 0px;
}
