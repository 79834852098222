.navbar {
  padding: 20px 40px;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.navbar-left {
  color: #116274;
  text-align: center;
  font-family: "Major-Mono-Display";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navbar-right {
  color: #000;
  text-align: center;
  font-family: "Work-Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 30px;
}

.logout-icon {
  cursor: pointer;
}
