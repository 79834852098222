.footer-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  gap: 30px;
  padding: 20px 40px;
  background: #fff;
  z-index: 2;
}

.footer-button {
  border-radius: 4px;
  background: #272829;
  display: flex;
  width: 180px;
  height: 40px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--white, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.08px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  border: none;
}

.footer-text {
  color: #116274;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.08px;
  text-transform: uppercase;
  cursor: pointer;
}

.footer-text:hover {
  text-decoration: underline;
}
