.post-box {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 342px;
}

.post-header {
  display: flex;
  width: -webkit-fill-available;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
}

.post-title {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Source-Code-Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.post-location,
.post-description {
  color: #666;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Source Code Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.post-description {
  margin-top: 5px;
}

.post-image {
  width: 342px;
  height: 198px;
}

.post-footer {
  display: flex;
  width: -webkit-fill-available;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 18px;
}

.post-footer-item-container {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.post-footer-item-image {
  width: 16px;
  height: 16px;
  border-radius: 100%;
}
